import React, { useEffect, useState } from 'react';
import './Projects.scss';
import ProjectItem from './ProjectItem';
import PortfolioServices from '~/services/PortfolioServices';
import timind from '~/assets/image/project/timind.png';
import profbeauty from '~/assets/image/project/profbeauty.png';
import thienvu from '~/assets/image/project/thienvu.png';
import uskolor from '~/assets/image/project/uskolor.png';

function Projects() {
    // const [projects, setProjects] = useState([]);
    // useEffect(() => {
    //     (async () => {
    //         const response = await PortfolioServices.getProjects();
    //         setProjects(response);
    //         console.log(response);
    //     })();
    // }, []);
    // console.log('projects', projects);
    const datas = [
        {
            subTitle: 'Timind.co',
            title: 'Timind.co',
            description: `
                <h2>Dự án Timind – Website Giới Thiệu Công Ty</h2>
                <p>Website Timind được xây dựng với mục tiêu giới thiệu tổng quan về công ty, các dịch vụ cung cấp và giá trị cốt lõi mà Timind mang lại. Giao diện website được thiết kế hiện đại, tối ưu trải nghiệm người dùng, giúp khách hàng dễ dàng tiếp cận thông tin và dịch vụ.</p>
                <h3>Các tính năng chính:</h3>
                <ul>
                    <li>Giới thiệu công ty: Cung cấp thông tin chi tiết về sứ mệnh, tầm nhìn và giá trị cốt lõi.</li>
                    <li>Dịch vụ: Trình bày các giải pháp và dịch vụ nổi bật mà công ty cung cấp.</li>
                    <li>Hiệu ứng tương tác: Ứng dụng hiệu ứng cuộn mượt mà (scroll animation) và hiệu ứng chuột di chuyển theo màn hình (mouse effect) bằng GSAP để tăng tính sinh động.</li>
                    <li>Form liên hệ: Cho phép khách hàng gửi yêu cầu tư vấn hoặc đăng ký trực tiếp trên website.</li>
                </ul>
                <h3>Công nghệ sử dụng:</h3>
                <ul>
                    <li>Frontend: HTML5, CSS3, JavaScript (GSAP cho hiệu ứng động).</li>
                    <li>Backend: PHP, MySQL.</li>
                </ul>
                `,
            image: timind,
            href: 'https://timind.co',
            tags: [
                {
                    name: 'figma',
                    href: '',
                },
                {
                    name: 'php',
                    href: '',
                },
                {
                    name: 'gsap.js',
                    href: '',
                },
                {
                    name: 'aos',
                    href: '',
                },
            ],
        },
        {
            title: 'Profbeauty.vn',
            description: 'ProfBeauty.vn là một hệ sinh thái truyền thông đa phương tiện chuyên sâu về thời trang và các thương hiệu cao cấp, với sứ mệnh truyền cảm hứng sống hiện đại cho phụ nữ thông qua việc cập nhật liên tục kiến thức làm đẹp chuyên nghiệp từ khắp nơi trên thế giới. ',
            image: profbeauty,
            href: 'https://profbeauty.vn',
            tags: [
                {
                    name: 'figma',
                    href: '',
                },
                {
                    name: 'php',
                    href: '',
                },
                {
                    name: 'infinite-scroll.js',
                    href: '',
                },
            ],
        },
        {
            title: 'Thiên vũ Audio',
            description: 'Thiên Vũ Audio là một showroom âm thanh nghe nhìn cao cấp, chuyên cung cấp các sản phẩm và giải pháp âm thanh chất lượng cao cho khách hàng. Với danh mục sản phẩm đa dạng, Thiên Vũ Audio mang đến cho người dùng nhiều lựa chọn phù hợp với nhu cầu và sở thích cá nhân.',
            href: 'https://thienvu.com.vn',
            image: thienvu,
            tags: [
                {
                    name: 'figma',
                    href: '',
                },
                {
                    name: 'php',
                    href: '',
                },
                {
                    name: 'Elasticsearch',
                    href: '',
                },
            ],
        },
        {
            title: 'Uskolor',
            description: 'USKOLOR là kết quả của sự hợp tác giữa phòng R&D Inno Invest và phòng hợp tác phát triển OCD, thuộc tập đoàn đầu tư toàn cầu Access Industries, có trụ sở tại thành phố Hồ Chí Minh, Việt Nam.',
            href: 'https://uskolor.com',
            image: uskolor,
            tags: [
                {
                    name: 'figma',
                    href: '',
                },
                {
                    name: 'php',
                    href: '',
                },
                {
                    name: 'Phối màu tường',
                    href: '',
                },
            ],
        },
    ];

    return (
        <div className="skills">
            <div className="skills__content">
                {datas && datas.map((project, index) => <ProjectItem key={index} project={project} />)}
            </div>
        </div>
    );
}

export default Projects;
