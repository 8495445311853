import axios from 'axios';
import Cookies from 'js-cookie';
//ktra xem co đang lấy file env không
const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
    timeout: 5000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    let userInfo;
    if (Cookies.get('userInfo')) {
        userInfo = JSON.parse(Cookies.get('userInfo'));
    }

    return {
        ...config,
        headers: {
            authorization: userInfo ? `Bearer ${userInfo.token}` : null,
        },
    };
});

// Add a response interceptor
instance.interceptors.response.use(
    function (response) {
        // console.log("response", response);
        return response?.data?.data || response;
    },
    function (error) {
        // console.log(error);
        return Promise.reject(error);
    },
);

const requests = {
    get: (url, body) => instance.get(url, body),
    post: (url, body, headers) => instance.post(url, body, headers),
    put: (url, body) => instance.put(url, body),
};

export default requests;
